.home-section {
  &__title {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;

    @media screen and (max-width: 1024px) {
      font-size: 2.125rem;
    }

    @media screen and (max-width: 576px) {
      font-size: 2rem;
    }
  }

  &__subtitle {
    padding: 0 20%;
    margin-bottom: 4rem;
    font-size: 1.125rem;
    text-align: center;
    color: var(--dark);
    opacity: 0.85;

    @media screen and (max-width: 1024px) {
      padding: 0 10%;
      margin-bottom: 3rem;
    }

    @media screen and (max-width: 1024px) {
      padding: 0;
    }
  }
}
