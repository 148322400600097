.dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    border-radius: 0.5rem;
  }

  .mat-mdc-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem 1rem;
    margin: 0;
    font-size: 1.125rem !important;
    line-height: 2rem !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    color: var(--dark) !important;

    &:before {
      display: none;
    }

    @media (max-width: 768px) {
      font-size: 1rem !important;
      line-height: 1.5rem !important;
    }
  }

  button.close {
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: none;
    border: none;

    @media (max-width: 768px) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .mat-mdc-dialog-content {
    margin: 0;
    font-family: "Nunito Sans", sans-serif !important;
    letter-spacing: 0 !important;
    color: var(--dark) !important;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    margin: 0;

    button {
      margin-left: 0.5rem;
    }
  }
}
