.default-tooltip .mdc-tooltip__surface {
  padding: 0.5rem 0.75rem !important;
  font-family: 'Nunito', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: var(--white) !important;
  border-radius: 6px !important;
  background: var(--dark) !important;
  opacity: 0.9 !important;
  -webkit-font-smoothing: antialiased;
}
