button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &[disabled] {
    opacity: 0.75;
  }
}

button.medium {
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  border-radius: 6px;

  @media screen and (max-width: 576px) {
    padding: 6px 10px;
    font-size: 13px;
  }
}

button.large {
  padding: 10px 16px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  border-radius: 6px;

  @media screen and (max-width: 576px) {
    padding: 8px 12px;
    font-size: 14px;
  }
}

button.x-large {
  padding: 18px 32px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.3px;
  border-radius: 6px;

  @media screen and (max-width: 576px) {
    padding: 16px 28px;
    font-size: 14px;
  }
}

.blue-button {
  color: var(--white);
  border: 1px solid var(--primary);
  background: var(--primary);

  &:hover {
    background: var(--blue);
  }
}

.white-button {
  color: var(--black-light);
  border: 1px solid var(--gray-400);
  background: var(--white);

  &:hover {
    background: var(--blue-100);
  }
}

.red-button {
  color: var(--white);
  border: 1px solid var(--danger);
  background: var(--danger);

  &:hover {
    opacity: 0.75;
  }
}

.green-button {
  color: var(--white);
  border: 1px solid var(--green);
  background: var(--green);

  &:hover {
    opacity: 0.75;
  }
}

.gray-button {
  color: var(--white);
  border: 1px solid var(--gray-800);
  background: var(--gray-800);

  &:hover {
    opacity: 0.75;
  }
}

.orange-button {
  color: var(--white);
  border: 1px solid var(--orange);
  background: var(--orange);

  &:hover {
    opacity: 0.75;
  }
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border: none;
  color: var(--black-light);
  background: var(--white);
  cursor: pointer;

  &:hover,
  &.active {
    background: var(--blue-100);
  }

  &--medium {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--primary {
    background: var(--primary-lighter);

    &:hover {
      background: var(--primary-lighter);
    }
  }
}
