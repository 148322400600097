.sidebar-menu {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__link {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0.375rem;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    border-radius: 0.5rem;
    cursor: pointer;
    @apply text-black-light dark:text-gray-600;

    &.active,
    &:hover {
      color: white;
      background: var(--primary);
    }

    &.active:before {
      content: "";
      position: absolute;
      left: -1rem;
      width: 3px;
      height: 2.5rem;
      border-radius: 0 0.25rem 0.25rem 0;
      background: var(--primary);
      overflow: hidden;
    }

    &--submenu.active {
      color: var(--dark);
      background: none;

      &:before {
        display: none;
      }
    }
  }

  &__icon {
    margin-right: 0.75rem;
  }

  &__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.125rem;
    height: 1.125rem;
    margin-left: auto;
    font-size: 0.5625rem;
    color: var(--white);
    border-radius: 0.25rem;
    background: var(--primary);
  }

  &__submenu {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding-left: 2.5rem;
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    height: 0;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    @apply text-black-light dark:text-gray-600;

    &--active {
      min-height: 0;
      height: auto;
      overflow: visible;
      transition-duration: 300ms;
    }
  }

  &__submenu-link {
    padding-left: 0.75rem;
    line-height: 1.5rem;
    cursor: pointer;
  }

  &__submenu-link.active {
    @apply text-primary dark:text-white;
  }

  &__submenu-icon {
    margin-left: auto;
    transition: all 100ms ease-in;

    &--active {
      transform: rotate(90deg);
    }
  }
}
