.quiz-reorder-answers__item {
  &.cdk-drag-preview {
    background: white;
    border-radius: 0.5rem;
    box-shadow: none !important;
  }

  &.cdk-drag-placeholder {
    display: block;
    width: 100%;
    border-radius: 0.5rem;
    background: var(--blue-100)
  }
}
