.table-search {
  display: flex;
  align-items: center;

  &__input {
    width: 100%;
    /* max-width: 220px; */
    padding: 0.5rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.5;
    font-weight: 400;
    border: none;
    outline: none;
    background: transparent;
  }
}
