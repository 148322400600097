.lang-selector {
  width: 160px !important;
  margin-bottom: 0.5rem;
  height: 1.25rem;
  font-size: 12px !important;
  line-height: 14px !important;
  border-radius: 0.25rem;
  background-color: rgba(55, 53, 47, 0.08);

  .mat-mdc-select-trigger {
    padding: 0 10px;
  }

  .mat-mdc-select-arrow-wrapper {
    height: 1.25rem;
  }

  .mat-mdc-select-arrow svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.lang-code-select {
  .mdc-list-item__primary-text {
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
  }

  .mat-mdc-option {
    min-height: 1.25rem !important;
    font-size: 0.75rem;
  }
}
