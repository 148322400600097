.editor {
  display: flex;
  flex-direction: column;
  row-gap: 0.1875rem;

  &__section-row {
    display: flex;
    align-items: center;
    position: relative;

    &:hover .editor__menu,
    &.cdk-drag-placeholder .content-editor__menu {
      display: flex;
    }
  }

  &__section {
    flex: 1;
    width: 100%;
  }

  &__menu {
    display: none;
    position: absolute;
    left: 0;
    top: 0.5rem;
    opacity: 0.7;

    &--p {
      top: 0.35rem;
    }

    &--opened {
      display: flex;
    }

    &:focus,
    &:active {
      display: flex;
    }
  }

  .block {
    padding: 0.325rem 0.5rem;
    margin-left: 2.5rem;
    border-radius: 0.25rem;

    &--h1 {
      font-size: 1.5em;
      line-height: 1.3;
    }

    &--h2 {
      font-size: 1.375rem;
      line-height: 1.3;
    }

    &--ul .list-item__content,
    &--ol .list-item__content {
      padding: 3px 0.5rem;
      width: 100%;
      border-radius: 0.25rem;

      &:focus {
        outline: none;
        background: var(--blue-100);
      }

      &:empty::before {
        content: attr(placeholder);
        color: var(--black-light);
        opacity: 0.7;
      }
    }

    &--delimiter {
      padding: 0.325rem 0.5rem;
      text-align: center;
    }

    &--pre[class*="language-"] {
      display: grid;
      margin-top: 0;
      margin-bottom: 0;
    }

    &--code {
      border-radius: 0.25rem;

      &:focus {
        outline: none;
      }
    }

    &--image,
    &--audio {
      display: block;
      width: auto;
      padding-left: 0;
      padding-right: 0;
    }

    &--input {
      opacity: 0;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 2.605rem;
      right: 0;
      bottom: 0;
      cursor: pointer;

      @media screen and (max-width: 1024px) {
        left: 2.825rem;
      }
    }

    &--empty-img,
    &--empty-audio {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.25rem 1rem;
      opacity: 0.75;
      background: var(--blue-100);
      border: 1px solid var(--gray-400);

      @media screen and (max-width: 1024px) {
        padding: 1rem 0.75rem;
      }

      &:hover {
        opacity: 1;
      }
    }

    &--empty-icon {
      margin-right: 0.5rem;
    }

    &:focus {
      outline: none;
      background: var(--blue-100);
    }

    &:empty::before {
      content: attr(placeholder);
      color: var(--black-light);
      opacity: 0.7;
    }

    &:empty:focus::before {
      content: "";
    }
  }
}
