.pagination {
  font-size: 0.8125rem !important;
  color: var(--dark) !important;
  border-top: 1px solid var(--gray-400);

  .mat-mdc-paginator-range-actions {
    margin-right: 1rem;

    @media screen and (max-width: 576px) {
      margin-right: 0;
    }
  }

  .mat-mdc-paginator-navigation-previous,
  .mat-mdc-paginator-navigation-next {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.35rem;
    padding: 0 !important;
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 0.35rem;
    background: var(--primary-lighter);

    &[disabled] {
      opacity: 0.5 !important;
    }
  }

  .mat-mdc-paginator-icon {
    width: 1.5rem !important;
    fill: var(--blue) !important;
  }

  .mat-mdc-select-value {
    font-size: 13px !important;
  }

  .mat-mdc-paginator-page-size {
    margin-right: 1rem;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
    width: 60px;
  }

  .mat-mdc-paginator-page-size-select {
    margin: 0 0 0 0.75rem;
    width: 40px;
  }

  .mat-mdc-form-field-flex {
    width: 60px;
    position: relative;
    border-radius: 6px;
    border: 1px solid var(--gray-400);
  }

  .mat-mdc-form-field-infix {
    padding: 0.25rem 0.5rem 0.25rem 0.75rem !important;
    min-height: 2rem;
    border-top: 0;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing{
    display: none;
  }

  .mat-mdc-select-arrow {
    color: var(--dark) !important;
  }

  @media screen and (max-width: 576px) {
    .mat-mdc-paginator-container {
      justify-content: flex-end;
    }
  }

  @media screen and (min-width: 576px) {
    .mat-mdc-paginator-container {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 576px) {
    .mat-mdc-paginator-range-label {
      margin: 0 0.5rem 0 0.5rem;
    }
  }
}
