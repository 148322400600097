.text-content {
  width: 100%;
  outline: none;
  caret-color: var(--black-light);
  word-wrap: break-word;
  white-space: pre-wrap;

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-base;
  }

  h6 {
    @apply text-sm;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500
  }

  h1, h2, h3 {
    margin-top: 0.5rem
  }

  h4, h5, h6 {
    margin-top: 0.375rem
  }

  p {
    @apply text-base;
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }

  ol,
  ul {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
  }

  ul li,
  ol li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: 1rem;
    width: calc(100% - 1rem);
    border-radius: 0.25rem;
  }

  pre {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.75rem;
    font-family: 'Nunito Sans', sans-serif !important;
    font-weight: 400;
    white-space: pre-wrap;
    text-shadow: 0 1px white;
    border-radius: 0.25rem;
    background: #f5f2f0;
  }

  code {
    padding: 0.125rem 0.375rem;
    font-family: 'Nunito Sans', sans-serif !important;
    font-weight: 400;
    white-space: pre-wrap;
    border-radius: 0.25rem;
    background: #f5f2f0;
  }

  a {
    color: var(--primary);
    text-decoration: underline;
  }

  blockquote {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: .5rem 1rem;
    border-left-width: 2px;
    border-left-color: var(--black-light);
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child,
  p:first-child,
  ul:first-child,
  ol:first-child,
  ul li:first-child,
  ol li:first-child,
  blockquote:first-child,
  pre:first-child {
    margin-top: 0
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child,
  ul:last-child,
  ol:last-child,
  ul li:last-child,
  ol li:last-child,
  blockquote:last-child,
  pre:last-child {
    margin-bottom: 0
  }

  // Quill editor styles
  .ql-align-center {
    text-align: center !important;
  }

  .ql-align-left {
    text-align: left !important;
  }

  .ql-align-right {
    text-align: right !important;
  }

  .ql-align-justify {
    text-align: justify !important;
  }
}
