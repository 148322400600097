@use "./table-search";
@use "./table-empty-state";

.mat-mdc-header-row {
  min-height: 48px;
  background: #f9fbfd !important;
  border-bottom: 1px solid var(--gray-400) !important;
}

.mat-mdc-row {
  border-bottom: 1px solid var(--blue-100) !important;;
}

.mat-mdc-header-cell {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--dark);
  border-bottom: none !important;
}

.mat-mdc-cell,
.header-mat-cell {
  border-bottom: none !important;
}

.table-wrapper {
  overflow-x: auto;
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 800px;
  padding: 0;
  font-size: 0.875rem;
  border: none;
  border-spacing: 0;
  overflow-y: auto;

  &--empty {
    min-width: 100%;
  }
}

.table-head {
  min-height: 48px;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #f9fbfd;

  &--empty {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.table-body {
  flex: 1;
}

.table-row {
  display: flex;
  align-items: center;
  min-height: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-400);
}

.table-header-cell, .table-cell {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;
  color: var(--dark);
  overflow: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

  &:first-of-type {
    padding-left: 1.5rem;
  }

  &__flex-none {
    flex: none;
  }
}

.table-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem 0;

  &__title {
    font-size: 1.125rem;

    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }
}

