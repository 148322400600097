.thumbnails {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  &__item {
    display: flex;
    padding-right: 0.25rem;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    @apply text-gray-800 dark:text-gray-600;

    &:after {
      content: '/';
      padding-left: 0.25rem;
    }

    &:last-of-type {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: auto;
      @apply text-black-light dark:text-gray-500;

      &:after {
        content: '';
      }
    }
  }
}
