:root {
  /* Primary Colors */
  --primary: #0948b3;
  --primary-rgb: 9, 72, 179;
  --primary-light: #009ef7;
  --primary-lighter: #E8F1FF;

  /* Red Shades */
  --red: #e70707;
  --red-rgb: 231, 7, 7;

  /* Orange Shades */
  --orange: #F89C47;

  /* Beige Shades */
  --beige: #FFF9F4;

  /* Neutral Colors */
  --white: #FFFFFF;
  --black: #000000;
  --black-rgb: 0, 0, 0;
  --black-light: #353840;

  /* Green Shades */
  --green: #007e50;
  --green-rgb: 0, 126, 80;
  --green-100: #007e5026;
  --green-500: #007e5080;

  /* Blue Shades */
  --blue: #265dcd;
  --blue-100: #f3f7fa;

  --dark: #353840;

  /* Gray Shades */
  --gray-400: #eaedf2;
  --gray-500: #b1bcce;
  --gray-600: #93a5be;
  --gray-750: #626D7C;
  --gray-800: #506690;
  --gray-900: #5e6278;
  --gray-950: #2a2e34;

  /* Status Colors */
  --success: #1AA053;
  --warning: #E14D2A;
  --danger: #e70707;

  /* Box Shadow */
  --box-shadow: 0 0.275rem 0.75rem -0.0625rem rgb(11, 15, 25, 0.06), 0 0.125rem 0.4rem -0.0625rem rgb(11, 15, 25, 0.03);
}

.primary {
  color: var(--primary);
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.danger {
  color: var(--danger)
}

.warning {
  color: var(--warning)
}

.orange {
  color: var(--orange);
}

.success {
  color: var(--success);
}

.white {
  color: var(--white);
}

.box-shadow {
  box-shadow: var(--box-shadow);
}


