.card {
  border-radius: 0.5rem;
  background-color: var(--white);
  border: 1px solid var(--gray-400);
  box-shadow: var(--box-shadow);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 1.125rem 1.75rem;
    border-bottom: 1px solid var(--gray-400);

    @media screen and (max-width: 576px) {
      padding: 1rem 1.25rem;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: bold;

    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }

  &__body {
    padding: 1.125rem 1.75rem;

    @media (max-width: 576px) {
      padding: 1rem 1.25rem;
    }
  }

  &__footer {
    padding: 1.125rem 1.75rem;
    border-top: 1px solid var(--gray-400);

    @media (max-width: 576px) {
      padding: 1rem 1.25rem;
    }
  }
}
