.lessons__row.cdk-drag-preview {
  .lessons__name-cell {
    width: 80%;
    flex: auto;
    cursor: pointer;

    @media screen and (max-width: 576px) {
      width: 65%;
    }
  }

  .lessons__status-cell {
    width: 20%;
    flex: auto;

    @media screen and (max-width: 576px) {
      width: 35%;
    }
  }
}
