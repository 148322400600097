.default-checkbox {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  appearance: none;
  border-radius: 0.125rem;
  border: 1px solid var(--gray-600);
  background: var(--white);
  cursor: pointer;

  &--small {
    width: 0.875rem;
    height: 0.875rem;
  }

  &:checked {
    background-color: var(--primary);
    border-color: var(--primary);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 70%;
    background-image: url("../../../assets/icons/checked.png");
  }
}

.default-checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  cursor: pointer;

  &--small {
    gap: 0.5rem;
    @apply text-sm;
  }
}
