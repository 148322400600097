.icon {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;

  @media (max-width: 576px) {
    width: 1.125rem;
    height: 1.125rem;
  }
}

.icon-x-large {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;

  @media (max-width: 576px) {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.icon-large {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;

  @media (max-width: 576px) {
    width: 1.375rem;
    height: 1.375rem;
  }
}

.icon-medium {
  flex-shrink: 0;
  width: 1.125rem;
  height: 1.125rem;

  @media (max-width: 576px) {
    width: 1rem;
    height: 1rem;
  }
}

.icon-small {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;

  @media (max-width: 576px) {
    width: 0.875rem;
    height: 0.875rem;
  }
}

.icon-x-small {
  flex-shrink: 0;
  width: 0.875rem;
  height: 0.875rem;

  @media (max-width: 576px) {
    width: 0.8125rem;
    height: 0.8125rem;
  }
}

.right-icon {
  margin-left: 0.5rem;
}

.left-icon {
  margin-right: 0.5rem;
}
