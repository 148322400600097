@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  line-height: 1.4;
}

body,
div,
mat-table,
mat-drawer-content {
  &::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
    background: var(--blue-100);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-500);
    border-radius: 6px;
  }
}

.wrapper {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    width: 90%;
    max-width: 90%;
    padding: 0;
  }
}

h1 {
  font-size: 72px;
  font-weight: 500;
  line-height: 1.4;

  @media screen and (max-width: 1024px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 576px) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 3rem;
  line-height: 1.4;
  font-weight: 500;

  @media screen and (max-width: 1024px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 500;

  @media screen and (max-width: 1024px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 576px) {
    font-size: 1.25rem;
  }
}

ul, ol {
  padding-left: 1.5em;
  margin-bottom: 0.75rem;
}

@layer base {
  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }
}

img {
  max-width: 100%;
}

.w-100 {
  width: 100%;
}

.mr-2 {
  margin-right: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.break-word {
  word-break: break-word;
}

.pointer {
  cursor: pointer;
}
