.mat-mdc-select-panel {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  box-shadow: none !important;
  border-radius: 0.5rem !important;
  border: 1px solid var(--gray-400);
}

.mat-mdc-option {
  min-height: 2rem !important;
  font-size: 0.875rem;
  letter-spacing: 0;
  color: var(--black-light) !important;
}

.mdc-list-item__primary-text {
  width: 100%;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 14px !important;
  line-height: 36px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  color: var(--black-light) !important;
}

.mat-mdc-select-panel .mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
  width: 0.75rem;
  color: var(--black-light) !important;
}

.mat-mdc-select-panel .mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
  border-left: 1px solid currentColor;
}

.mat-mdc-select-panel .mat-pseudo-checkbox::after {
  border-bottom: 1px solid currentColor;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mat-mdc-option-disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: var(--blue-100) !important;
}

.mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
  border-radius: 6px !important;
}

.default-select {
  margin: 0.375rem 0;

  .mat-mdc-form-field-underline {
    display: none;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: auto;
    border-top: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-400);
    background: var(--white) !important;
  }

  .mat-mdc-form-field-flex {
    padding: 0.5rem 0.75rem !important;
  }

  .mat-mdc-select-value-text,
  .mat-mdc-select-placeholder {
    @apply text-sm;
  }

  .mat-mdc-select-arrow {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .mat-mdc-select-arrow-wrapper {
    margin-left: 0.375rem;
    transform: none !important;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
  }

  .mdc-line-ripple,
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
