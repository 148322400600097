.program-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid var(--blue-100) !important;

  @media (max-width: 576px) {
    padding: 0.75rem 1rem;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &__main {
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 576px) {
      font-size: 0.875rem;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__children-list {
    padding-left: 2.75rem;

    @media (max-width: 576px) {
      padding-left: 2.25rem;
    }
  }

  &__dnd-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 0.75rem;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    @media (max-width: 576px) {
      margin-right: 0.5rem;
    }
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 1rem;
    border-radius: 100%;
    border: none;
    background: var(--blue-100);

    @media screen and (max-width: 576px) {
      width: 2rem;
      height: 2rem;
      margin-right: 0.75rem;
    }
  }

  &__icon {
    width: 1.25rem;

    @media screen and (max-width: 576px) {
      width: 1rem;
    }
  }

  &__info-wrapper {
    width: 100%;
    overflow: hidden;
    border: none;
    background: none;
  }

  &__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__available-date {
    font-size: 13px;
    color: var(--black-light);
    opacity: 0.6;

    @media screen and (max-width: 576px) {
      font-size: 11px;
    }
  }

  &.cdk-drag-preview {
    background: white;
    border: 1px solid var(--blue-100) !important;
  }

  &__module-container.cdk-drag-preview {
    background: white;
    border: 1px solid var(--blue-100) !important;
  }

  &__drag-placeholder.cdk-drag-placeholder {
    display: block;
    width: 100%;
    height: 69px;
    background: var(--blue-100)
  }
}
