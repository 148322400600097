.mat-datepicker-content {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  min-width: 98px;
  font-size: 0.875rem;
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid var(--gray-400) !important;

  .mat-calendar-header {
    padding-top: 0;

    .mat-calendar-period-button {
      padding: 0 0.75rem 0 1rem;
      background: var(--blue-100);
      color: var(--dark);
    }

    .mat-calendar-arrow {
      margin-left: 0.75rem;
    }

    .mdc-icon-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      padding: 0;
    }

    .mat-calendar-previous-button:after, .mat-calendar-next-button:after {
      border-color: var(--dark);
      margin: 0.75rem;
      opacity: 0.75;
    }
  }

  .mat-calendar-table-header th {
    padding-bottom: 2px;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, .54);
  }

  .mat-calendar-body-cell-content {
    color: var(--dark);
    border: none;
    border-radius: 6px;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):not(.mat-calendar-body-active) .mat-calendar-body-today,
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: var(--dark);
    background: var(--blue-100) !important;
  }

  .mat-calendar-body-cell.mat-calendar-body-disabled {
    opacity: 0.5;
  }

  .mat-calendar-body-cell.mat-calendar-body-active .mat-calendar-body-cell-content {
    color: var(--white);
    background: var(--primary) !important;
  }
}

.mat-timepicker-panel {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0 !important;
  min-width: 98px;
  font-size: 0.875rem;
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid var(--gray-400) !important;

  .mdc-list-item__primary-text {
    @apply text-sm !important;
  }

  .mat-mdc-option .mat-pseudo-checkbox::after {
    border-color: var(--black-light) !important;
    opacity: 0.7;
  }
}