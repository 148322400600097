.mat-mdc-tab-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  height: 2.5rem !important;
  flex-grow: 0 !important;
  opacity: 0.6;

  .mdc-tab__text-label {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    letter-spacing: 0;
    color: var(--primary) !important;
  }

  .mdc-tab__ripple {
    display: none;
  }

  .mdc-tab-indicator__content {
    border-top-width: 1px !important;
    border-color: var(--primary) !important;
  }

  &.mdc-tab--active {
    color: var(--primary);
    opacity: 1;
  }
}
