.form-field {
  margin: 0.5rem 0;
  width: 100%;
}

.form-row {
  display: flex;
  margin: 0.5rem 0;
  width: 100%;

  .default-label {
    margin-right: 1rem;
    margin-top: 0.5rem;
    width: 20%;
    flex-shrink: 0;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;

    .default-label {
      width: 100%;
    }
  }
}
