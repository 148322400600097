.lesson-navigation {
  width: 100%;
  min-width: 1024px !important;
  max-height: 75vh !important;
  padding: 20px 30px !important;
  border: none;
  box-sizing: border-box;
  transform: translate(-30px, -25px) !important;
  background: var(--dark) !important;

  @media screen and (max-width: 1084px) {
    min-width: calc(100vw - 60px) !important;
  }

  @media screen and (max-width: 1024px) {
    min-width: 90vw !important;
  }

  @media screen and (max-width: 576px) {
    min-width: 100vw !important;
    transform: translate(0, -18px) !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--gray-400);
  }

  .mat-mdc-menu-item {
    padding: 0;

    &:hover {
      background: transparent !important;

      .mdc-list-item__primary-text {
        font-weight: bold !important;
        color: var(--success) !important;
      }
    }
  }

  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--white) !important;
  }
}
