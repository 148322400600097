.default-textarea {
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  padding: 0.6rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-400);
  resize: none;

  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }

  &.ng-invalid.ng-touched {
    border: 1px solid var(--danger);
  }

  &_gray {
    background: var(--blue-100);
  }
}
