.default-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.default-input {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 0.375rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-400);

  @media screen and (max-width: 576px) {
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;

    &::placeholder {
      font-size: 0.875rem;
    }
  }

  &.ng-invalid.ng-touched {
    border: 1px solid var(--danger);
  }

  &_gray {
    background: var(--blue-100);
  }
}

.helper-text {
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  text-align: left;
  color: var(--dark);
  opacity: 0.75;
}

mat-error,
.validation-error {
  display: inline-flex;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  text-align: left;
  color: var(--danger);
}
