.table-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  &__title {
    font-size: 1.125rem;

    @media screen and (max-width: 1024px) {
      font-size: 1rem;
    }
  }

  &__icon {
    display: block;
    margin-bottom: 0.75rem;
  }
}
